import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import './FieldError.scss';

export type TError = string | string[];

export interface IFieldErrorProps {
  /**
   * Текст с одной или несколькими ошибками
   * @default span
   */
  as?: 'span' | 'div';

  /** Текст с одной или несколькими ошибками */
  error: string | string[];
}

const errorTextElement: FC<string> = (text: string): JSX.Element | null =>
  text ? (
    <div className="field-error-text d-flex align-items-center">
      <i className="fa-solid fa-circle-exclamation mr-1" />
      <span>{text}</span>
    </div>
  ) : null;

export const FieldError = ({ error, as = 'span' }: IFieldErrorProps): JSX.Element | null =>
  typeof error === 'string' || Array.isArray(error) ? (
    <Form.Control.Feedback
      as={as}
      type="invalid"
      style={{ display: as === 'span' ? 'inline' : 'block', position: 'absolute' }}>
      {Array.isArray(error)
        ? error.map((error, idx) => (
            <div key={`error-${idx}`}>
              {idx + 1}. {error}
            </div>
          ))
        : errorTextElement(error)}
    </Form.Control.Feedback>
  ) : null;
